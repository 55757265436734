import styled from "styled-components";
import { Colors } from "../Theme";
import { useState, useEffect } from "react";
import { BaseWebURL } from '../Constant';

const HeaderEl = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  background-color: ${(props) =>
    props.isScrolled ? "rgba(0, 0, 0, 0.7)" : "transparent"};
  transition: all ease 1s;
`;

const HeaderWrapper = styled.div`
  max-width: 1920px;
  height: 100px;
  display: flex;
  width: 100%;
  padding: 0 2rem 0 2rem;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    height: 60px;
    padding: 0 1.5rem 0 1.5rem;
  }
`;

const LogoBox = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: white;
`;

const HeaderLogoImg = styled.img`
  @media (max-width: 1000px) {
    width: 37px;
  }
`;

const HeaderMenuList = styled.ul`
  display: flex;
  gap: 4rem;
  list-style: none;
  align-items: center;
  font-size: 18px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const HeaderMenuLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: solid;
    color: ${Colors.purple01};
  }
`;

const HeaderRightBox = styled.div`
  display: flex;
  gap: 8px;
`;

const LanguageDropdownEl = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (max-width: 1000px) {
    padding: 8px;
  }
`;

const CurrentLanguage = styled.span`
  padding-left: 0.5rem;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const LanguageMenu = styled.div`
  display: none;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
  background-color: ${Colors.purple01};
  ${LanguageDropdownEl}:hover & {
    display: inline-block;
  }
  @media (max-width: 1000px) {
    transform: translateX(-50%) translateY(-4px);
  }
`;

const LanguageMenuItem = styled.div`
  padding: 1rem 1.5rem;
  &:hover {
    background-color: ${Colors.purple02};
  }
`;

const BtnMobileMenu = styled.img`
  display: none;
  z-index: 800;
  width: 32px;
  @media (max-width: 1000px) {
    display: inline-block;
  }
`;

const MobileMenuSliderEl = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 700;
  width: 100%;
  height: 100%;
  transition: 1s all;
  background-color: ${Colors.gr08};
  flex-direction: flex-end;
  ul {
    list-style: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 25px;
    padding: 0;
  }
`;

const MobileMenuHeaderWrapper = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  justify-content: flex-end;
`;
function Header({language, setLanguage}) {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scroll = this.scrollY;
      if (scroll > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  function mobileMenuHandler() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }
  
  function languageHandler(lng){
    if(lng === "KR"){
      setLanguage("KR");
    }else if(lng === "EN"){
      setLanguage("EN");
    }
  }
  return (
    <div>
      <HeaderEl isScrolled={isScrolled}>
        <HeaderWrapper>
          <LogoBox href={BaseWebURL}>
            <HeaderLogoImg src={"/images/BI_my_square_symbol.svg"} alt="logo" />
          </LogoBox>
          <HeaderMenuList>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#home"}> Home</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#mysquare"}>My Square?</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#artist"}>Artist</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#roadmap"}>Roadmap</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#faq"}>FAQ</HeaderMenuLink>
            </li>
          </HeaderMenuList>
          <HeaderRightBox>
            <LanguageDropdownEl>
              <img src={"icons/language.svg"} alt="language" />
              <CurrentLanguage>{language}</CurrentLanguage>
              <LanguageMenu>
                <LanguageMenuItem onClick={(e)=>{languageHandler("KR",e)}}>KR</LanguageMenuItem>
                <LanguageMenuItem onClick={(e)=>{languageHandler("EN",e)}}>EN</LanguageMenuItem>
              </LanguageMenu>
            </LanguageDropdownEl>
            <BtnMobileMenu
              onClick={mobileMenuHandler}
              src={"icons/mobile_menu.svg"}
              alt="mobile menu open"
            />
          </HeaderRightBox>
        </HeaderWrapper>
      </HeaderEl>
      {isMobileMenuOpen && (
        <MobileMenuSliderEl>
          <HeaderEl>
            <MobileMenuHeaderWrapper>
              <HeaderRightBox>
                <BtnMobileMenu
                  onClick={mobileMenuHandler}
                  src={"icons/close.svg"}
                  alt="mobile menu close"
                />
              </HeaderRightBox>
            </MobileMenuHeaderWrapper>
          </HeaderEl>
          <ul>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#home"}>Home</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#mysquare"}>My Square?</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#artist"}>Artist</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#roadmap"}>Roadmap</HeaderMenuLink>
            </li>
            <li>
              <HeaderMenuLink href={BaseWebURL+"#faq"}>FAQ</HeaderMenuLink>
            </li>
          </ul>
        </MobileMenuSliderEl>
      )}
    </div>
  );
}
export default Header;
