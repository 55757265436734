import styled from "styled-components";
import { Colors } from "../Theme";

const ModalBackground = styled.div`
  display: block;
  width: 100%;
  height: 300vh;
  background-color: ${Colors.gr09};
  opacity: 0.9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: white;
  color: black;
  width: 460px;
  padding : 2rem;
  border-radius: 8px;
  @media (max-width: 1000px) {
    width: 70%;
    padding : 1rem;
  }
`;

const ModalEl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CloseBox = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

const TitleText = styled.span`
  font-size: 20px;
  color: ${Colors.gr09};
`;

const ConfirmButton = styled.button`
  border: 1px solid ${Colors.gr04};
  color: ${Colors.gr08};
  background: none;
  padding: 0rem 1rem 0rem 1rem; 
  height: 46px;
  font-size: 16px;
  font-family: regular;
  margin-top: 5rem;
  cursor: pointer;
  :hover{
    background-color: ${Colors.purple01};
    border: 1px solid ${Colors.purple01};
    color: ${Colors.wt};
  }
  @media (max-width: 1000px) {
    margin-top: 3rem;
  }
`;

function ModalDisconnect({ setIsModalOpen, disconnectHandler, language }) {
  function modalCloseHandler() {
    setIsModalOpen(false);
  }

  return (
    <div>
      <ModalBackground onClick={modalCloseHandler}></ModalBackground>
      <ModalBox>
        <ModalEl>
          <CloseBox>
            <img src={"/icons/close.svg"} alt="close" onClick={modalCloseHandler} style={{cursor:"pointer"}}/>
          </CloseBox>
          <TitleText>{language==="KR" ? "지갑 연결을 해제할까요?" : "Do you want to Disconnect your wallet?"}</TitleText>
          <ConfirmButton onClick={disconnectHandler}>{language==="KR" ? "확인" : "Confirm"}</ConfirmButton>
        </ModalEl>
      </ModalBox>
    </div>
  );
}

export default ModalDisconnect;
