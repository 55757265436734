import styled from "styled-components";
import { Colors } from "../Theme";
import ModalWallet from "../components/ModalWallet";
import { useState, useEffect } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import swal from "sweetalert2";
import detectEthereumProvider from "@metamask/detect-provider";

const MainEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  margin-bottom: 10rem;
  @media (max-width: 1000px) {
    padding-top: 5rem;
    margin-bottom: 3rem;
  }
`;

const TitleText = styled.span`
  font-size: 56px;
  font-weight: 500;
  text-align: center;
  line-height: 120%;
  margin-bottom: 2rem;
  @media (max-width: 1000px) {
    font-size: 34px;
  }
`;

const SubTitleText = styled.span`
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  margin-bottom: 4rem;
  color: ${Colors.gr02};
  white-space: pre-wrap;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const VideoBox = styled.video`
  width: 280px;
  margin-bottom: 3rem;
  box-shadow: 0 0 12px 4px ${Colors.gr08};
`;

const ConnectWalletBtn = styled.button`
  background: linear-gradient(-45deg, #518eee, #825cf9, #d669cd);
  color: ${Colors.wt};
  padding: 0 2.5rem 0 2.5rem;
  height: 60px;
  border: none;
  cursor: pointer;
  font-family: regular;
  font-size: 18px;
  :hover {
    background: ${Colors.purple01};
  }
`;

function ConnectWalletPage({
  web3,
  setWeb3,
  setIsConnected,
  setWalletAddress,
  language,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModalHandler() {
    setIsModalOpen(true);
  }

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: process.env.REACT_APP_INFURA_KEY,
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: "mainnet",
    theme: "dark",
    cacheProvider: false,
    providerOptions,
    disableInjectedProvider: true,
  });

  useEffect(() => {
    async function afterConnectWallet() {
      let accounts = await web3.eth.getAccounts();
      let account = accounts[0];
      // console.log("connect wallet : ", account);
      setWalletAddress(account);
      setIsModalOpen(false);
      setIsConnected(true);
    }
    if (web3 !== null) {
      afterConnectWallet();
    }
  }, [setIsConnected, setWalletAddress, web3]);

  async function connectwallet(type) {
    try {
      if (type === "Burrito") {
        web3Modal.clearCachedProvider();
        localStorage.clear();
        let provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        setWeb3(web3);
      } else if (type === "Metamask") {
        const detectProvider = await detectEthereumProvider();
        let provider;
        if (detectProvider.providerMap === undefined) {
          provider = window.ethereum;
        } else {
          console.log(detectProvider.providerMap.get("MetaMask"));
          if (detectProvider.providerMap.get("MetaMask") === undefined) {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "Please install MetaMask first.",
            });
            return;
          }
          provider = detectProvider.providerMap.get("MetaMask");
        }
        if (provider) {
          try {
            //Check Network
            if (
              Number(provider.networkVersion) !==
              Number(process.env.REACT_APP_NETWORK_ID)
            ) {
              swal.fire({
                icon: "error",
                title: "Error",
                text: "Please change network.",
              });
              return;
            }
            //Set Web3
            const web3 = new Web3(provider);
            await provider
              .request({
                method: "eth_requestAccounts",
              })
              .then(() => setWeb3(web3));
          } catch (error) {
            if (error.code === 4001) {
              console.log("Please connect to MetaMask.");
              swal.fire({
                icon: "error",
                title: "Error",
                text: "Please connect to MetaMask.",
              });
            } else {
              console.error(error);
              swal.fire({
                icon: "error",
                title: "Error",
                text: error.message,
              });
            }
          }
        } else {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Please install metamask first :(",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <MainEl>
      <TitleText>
        Get your
        <br />
        My Square NFT
      </TitleText>
      <SubTitleText>
        {language === "KR"
          ? "부리또 월렛 제네시스의 첫 번째 NFT 프로젝트에\n참여하기 위해 지갑을 연결해 주세요."
          : "Please connect your wallet to\nparticipate in Burrito Wallet Genesis' first NFT project."}
      </SubTitleText>
      <VideoBox autoPlay muted loop playsInline>
        <source
          src={"https://mysquare.burritowallet.com/mp4/Buttiro_wallet_animation_my2_shapes_01.mp4"}
          type="video/mp4"
        />
        {/* 업로드 서버에 비디오 저장 필요 */}
      </VideoBox>
      <ConnectWalletBtn onClick={openModalHandler}>
        Connect Wallet
      </ConnectWalletBtn>
      {isModalOpen && (
        <ModalWallet
          setIsModalOpen={setIsModalOpen}
          connectwallet={connectwallet}
        />
      )}
    </MainEl>
  );
}
export default ConnectWalletPage;
