export const Colors = {
    wt:'#ffffff',
    gr00:'#f8f9fa',
    gr01:'#f1f3f5',
    gr02:'#e9ecef',
    gr03:'#dee2e6',
    gr04:'#ced4da',
    gr05:'#adb5bd',
    gr06:'#868e96',
    gr07:'#495057',
    gr08:'#30353a',
    gr09:'#212529', 
    gr10:'#141619',

    purple01:'#5d44da',
    purple02:'#8475ef',
    purple03:'#bab0ff',
}