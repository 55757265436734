import styled from "styled-components";
import { Colors } from "../Theme";
import { BaseWebURL } from '../Constant';

const FooterEl = styled.footer`
  position: relative;
  width: 100%;
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;
  border-top: 1px solid ${Colors.gr07};
  @media (max-width: 1000px) {
    margin-top: 100px;
    margin-bottom: 5rem;
    border: 0;
  }
`;

const FooterWrapperEl = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 2rem;
`;

const FooterTopBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const FooterLogoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FooterLogoImg = styled.img`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const FooterLogoImgMobile = styled.img`
  display: none;
  @media (max-width: 1000px) {
    display: inline-block;
  }
`;

const FooterMenuBox = styled.div``;

const FooterBottomBox = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterMenuList = styled.ul`
  display: flex;
  gap: 1rem;
  list-style: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    border: 1px solid ${Colors.gr07};
    padding: 0;
    cursor: pointer;
    :hover{
        background: ${Colors.purple01};
        border: 1px solid ${Colors.purple01};
    }
  }
`;

const FooterMenuLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 0.4rem 1.5rem 0.4rem 1.5rem;
    color: ${Colors.wt};
    font-size: 14px;
    width: 100%;
    height: 100%;
    gap: 4px;
`;

const FooterCopyright = styled.span`
  color: ${Colors.gr06};
  font-size: 14px;
`;

const FooterPolycy = styled.a`
  color: ${Colors.gr04};
  font-size: 14px;
  text-decoration: none;
`;

function Footer() {
  return (
    <FooterEl>
      <FooterWrapperEl>
        <FooterTopBox>
          <FooterLogoBox>
            <FooterLogoImg src={"images/BI_my_square_type1.svg"} alt="logo" />
            <FooterLogoImgMobile
              src={"images/BI_my_square_type2.svg"}
              alt="logo"
            />
          </FooterLogoBox>
          <FooterMenuBox>
            <FooterMenuList>
              <li>
                <FooterMenuLink href={"https://discord.gg/QRGccchqjb"} target="_blank" rel="noreferrer">
                <img src={"icons/Logo-discord.svg"} alt="discord" />
                Discord
                </FooterMenuLink>
              </li>
              <li>
              <FooterMenuLink href={"https://twitter.com/MySquare_NFT"} target="_blank" rel="noreferrer">
                <img src={"icons/Logo-twitter.svg"} alt="twitter" />
                Twitter
                </FooterMenuLink>
              </li>
            </FooterMenuList>
          </FooterMenuBox>
        </FooterTopBox>
        <FooterBottomBox>
          <FooterCopyright>
            ⓒ 2023 My Square. All Rights Reserved.
          </FooterCopyright>
          <FooterPolycy href={BaseWebURL + "privacy.html"}>Privacy Policy</FooterPolycy>
        </FooterBottomBox>
      </FooterWrapperEl>
    </FooterEl>
  );
}
export default Footer;
