import styled from "styled-components";
import { Colors } from "../Theme";

const ModalBackground = styled.div`
  display: block;
  width: 100%;
  height: 300vh;
  background-color: ${Colors.gr09};
  opacity: 0.9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: white;
  color: black;
  width: 466px;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 8px;
  @media (max-width: 1000px) {
    width: auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
`;

const SelectWalletEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled.span`
  font-size: 30px;
  color: ${Colors.gr09};
  margin-bottom: 0.8rem;
`;

const SubtitleText = styled.span`
  font-size: 16px;
  color: ${Colors.gr09};
  margin-bottom: 1.5rem;
`;

const WalletBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 26px;
  padding: 1rem;
  border: 1px solid ${Colors.gr04};
  margin: 0.5rem;
  cursor: pointer;
  :hover{
    background-color: ${Colors.gr00};
  }
  @media (max-width: 1000px) {
    width: 280px;
  }
`;

const WalletBoxText = styled.span`
  color: ${Colors.gr09};
`;

const WalletIconImgBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ModalWallet({ setIsModalOpen, connectwallet }) {
  function modalCloseHandler() {
    setIsModalOpen(false);
  }
  return (
    <div>
      <ModalBackground onClick={modalCloseHandler}></ModalBackground>
      <ModalBox>
        <SelectWalletEl>
          <TitleText>Select your wallet</TitleText>
          <SubtitleText>Please connect your wallet.</SubtitleText>
          <WalletBox onClick={(e) => {
              connectwallet("Burrito", e);
            }}>
            <WalletBoxText>WalletConnect</WalletBoxText>
            <WalletIconImgBox>
              <img
                src={"/images/BI_symbol_typo_wt.png"}
                width="16"
                alt="wallet connect"
              />
            </WalletIconImgBox>
          </WalletBox>
          <WalletBox onClick={(e) => {
              connectwallet("Metamask", e);
            }}>
            <WalletBoxText>MetaMask</WalletBoxText>
            <WalletIconImgBox>
              <img
                src={"/images/metamask.png"}
                width="32"
                height="32"
                alt="wallet connect"
              />
            </WalletIconImgBox>
          </WalletBox>
        </SelectWalletEl>
      </ModalBox>
    </div>
  );
}

export default ModalWallet;
