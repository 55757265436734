import styled from "styled-components";
import { Colors } from "../Theme";
import { useEffect, useState } from "react";
import ModalDisconnect from "../components/ModalDisconnect";
import Lottie from "lottie-react";
import animationData from "../common_loading.json";
import ContractBuildJson from "../ContractBuild.json";
import merkleTree from "../tree.json";
import { StandardMerkleTree } from "@openzeppelin/merkle-tree";
import swal from "sweetalert2";

const MainEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  margin-bottom: 10rem;
  @media (max-width: 1000px) {
    padding-top: 7rem;
    margin-bottom: 5rem;
  }
`;

const TitleText = styled.span`
  font-size: 56px;
  color: ${Colors.gr00};
  @media (max-width: 1000px) {
    font-size: 34px;
  }
`;

const MintingStage = styled.span`
  background-color: ${Colors.gr08};
  color: ${Colors.gr01};
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 14px;
  border-radius: 3px;
  margin-top: 1.5rem;
  @media (max-width: 1000px) {
    margin-top: 1rem;
  }
`;

const ContentsEl = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 5rem;
  gap: 2rem;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
`;

const ImgBox = styled.img`
  width: 315px;
  height: 315px;
  border-radius: 5px;
  @media (max-width: 1000px) {
    width: 210px;
    height: 210px;
  }
`;

const RightContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.gr08};
  border-radius: 5px;
  width: 600px;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 1000px) {
    width: 100%;
    border: none;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

const CountDownEl = styled.div`
  border-bottom: 1px solid ${Colors.gr08};
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const CountDownBox = styled.div`
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const CountDownText = styled.span`
  font-size: 16px;
  color: ${Colors.gr05};
`;

const WalletAddressBox = styled.div`
  border: 1px solid ${Colors.gr07};
  color: ${Colors.gr03};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.4rem 0.5rem 1.4rem;
  gap: 0.3rem;
  cursor: pointer;
  :hover {
    background: ${Colors.purple01};
  }
`;

const MintCountEl = styled.div`
  border-bottom: 1px solid ${Colors.gr08};
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8rem;
  @media (max-width: 1000px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const CountTextBox = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const RightBoxText = styled.span`
  color: ${Colors.gr05};
  font-size: 16px;
`;

const RightBoxHighlightText = styled.span`
  color: ${Colors.wt};
  font-size: 16px;
  font-weight: 500;
`;

const GaugeBox = styled.div`
  width: 100%;
  position: relative;
`;

const GaugeBackground = styled.div`
  width: 100%;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.gr08};
  border-radius: 6px;
`;

const Gauge = styled.div`
  width: ${(props) => props.percent * 100 + "%"};
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(-45deg, #794fef, #9c59e2);
  border-radius: 6px;
`;

const GaugeIndexTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${Colors.gr02};
  font-size: 14px;
  margin-top: 0.5rem;
`;

const PriceEl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.gr08};
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: 1000px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const PriceBox = styled.div``;

const PriceText = styled.span`
  color: ${Colors.wt};
  font-size: 16px;
  font-weight: 500;
  padding-right: 8px;
`;

const AmountEl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: 1000px) {
    padding-top: 20px;
    padding-bottom: 32px;
  }
`;

const AmountControlBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const MintAmountText = styled.span`
  font-size: 30px;
  width: 19px;
  @media (max-width: 1000px) {
    font-size: 22px;
  }
`;

const MintingButton = styled.button`
  background: ${(props) =>
    props.isActive
      ? "linear-gradient(-45deg, #518eee, #825cf9, #d669cd)"
      : Colors.gr06};
  color: ${Colors.wt};
  height: 46px;
  margin-bottom: 2rem;
  border: none;
  cursor: ${(props) => (props.isActive ? "pointer" : "not-allowed")};
  font-family: regular;
  font-size: 16px;
`;

const LoadingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.gr07};
  color: ${Colors.wt};
  height: 46px;
  margin-bottom: 2rem;
  border: none;
  font-family: regular;
  font-size: 16px;
`;

function MintingPage({
  web3,
  setWeb3,
  setIsConnected,
  walletAddress,
  language,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mintingAmount, setMintingAmount] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const [isMintPossible, setIsMintPossible] = useState(false);
  const [currentStep, setCurrentStep] = useState("");
  const [currentStepText, setCurrentStepText] = useState("");
  const [countDown, setCountDown] = useState(-1);
  const [countDownText, setCountDownText] = useState("");
  const [mintPrice, setMintPrice] = useState(0);
  const [stepSupply, setStepSupply] = useState(0);
  const [stepCount, setStepCount] = useState(0);
  const [maxMintPerTx, setMaxMintPerTx] = useState(1);
  const [userLimit, setUserLimit] = useState(0);
  const [userProof, setUserProof] = useState([
    "0x0000000000000000000000000000000000000000",
  ]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletPreSaleCount, setWalletPreSaleCount] = useState(0);

  const ContractAddress =
    ContractBuildJson.networks[process.env.REACT_APP_NETWORK_ID].address;
  const ABI = ContractBuildJson.abi;

  useEffect(() => {
    getProof();
    getCurrentStep();
    getWalletBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countDown === 0) {
      setMintStepInfo(currentStep + 1);
    }
    countDown > 0 &&
      setTimeout(() => {
        setCountDown(countDown - 1);
        let seconds = countDown - 1;
        const days = Math.floor(seconds / (3600 * 24));
        const hours = Math.floor((seconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        setCountDownText(
          days +
            " days " +
            hours +
            " hours " +
            minutes +
            " mins " +
            remainingSeconds +
            " sec"
        );
      }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  function getCurrentTime() {
    let result = Math.floor(new Date().getTime() / 1000);
    return result;
  }
  async function getCurrentStep() {
    getPreSaleCount();
    getMaxMintPerTx();
    const contract = new web3.eth.Contract(ABI, ContractAddress);
    await contract.methods
      .getCurrentStep()
      .call()
      .then((data) => {
        // console.log("current step : " + data);
        setCurrentStep(parseInt(data));
        setMintStepInfo(parseInt(data));
      });
  }

  async function getMaxMintPerTx() {
    const contract = new web3.eth.Contract(ABI, ContractAddress);
    let step = 0;
    if (currentStep < 2) {
      step = 0;
    } else {
      step = 1;
    }
    try {
      await contract.methods
        .StepInfoList(step)
        .call()
        .then((data) => {
          // console.log("getMaxAmount : " + data.maxMintPerTx);
          setMaxMintPerTx(parseInt(data.maxMintPerTx));
        });
    } catch (error) {
      console.log(error);
      getMaxMintPerTx();
    }
  }

  async function setMintStepInfo(_step) {
    const contract = new web3.eth.Contract(ABI, ContractAddress);
    let index = 0;
    if (_step < 2) {
      index = 0;
    } else {
      index = 1;
    }

    try {
      await contract.methods
        .StepInfoList(index)
        .call()
        .then((data) => {
          if (_step === 0 || _step === 2) {
            setCountDown(data.startTime - getCurrentTime());
          } else if (_step === 1 || _step === 3) {
            setCountDown(data.finishTime - getCurrentTime());
          }
          if (_step === 0 || _step === 1) {
            setStepCount(parseInt(data.count));
          } else if (_step === 2 || _step === 3) {
            contract.methods
              .totalSupply()
              .call()
              .then((data) => {
                setStepCount(parseInt(data));
              });
          }
          setMintPrice(web3.utils.fromWei(data.price, "ether"));
          setStepSupply(parseInt(data.supply));
        });
    } catch (error) {
      console.log(error);
      setMintStepInfo(_step);
    }

    let stepText = "";
    let possible = false;
    if (_step === 0) {
      stepText = "Before Presale";
      possible = false;
    } else if (_step === 1) {
      stepText = "Presale";
      possible = true;
    } else if (_step === 2) {
      stepText = "Before Public Minting";
      possible = false;
    } else if (_step === 3) {
      stepText = "Public Minting";
      possible = true;
    } else if (_step === 4) {
      stepText = "Minting Finish";
      possible = false;
    }
    setIsMintPossible(possible);
    setCurrentStepText(stepText);
  }

  function openModalHandler() {
    setIsModalOpen(true);
  }
  function disconnectHandler() {
    setWeb3(null);
    setIsConnected(false);
  }
  function amountControlHandler(type) {
    if (type === "plus") {
      if (mintingAmount < maxMintPerTx) setMintingAmount(mintingAmount + 1);
    } else if (type === "minus") {
      if (mintingAmount > 1) setMintingAmount(mintingAmount - 1);
    }
  }

  function getProof() {
    const tree_load = StandardMerkleTree.load(merkleTree);
    for (const [i, v] of tree_load.entries()) {
      if (v[0] === walletAddress) {
        setUserProof(tree_load.getProof(i));
        setUserLimit(v[1]);
        break;
      }
    }
  }

  async function getWalletBalance() {
    await web3.eth.getBalance(walletAddress).then((data) => {
      setWalletBalance(parseInt(data));
    });
  }

  async function getPreSaleCount() {
    const contract = new web3.eth.Contract(ABI, ContractAddress);
    try {
      await contract.methods
        .preSaleCountList(walletAddress)
        .call()
        .then((data) => {
          setWalletPreSaleCount(parseInt(data));
        });
    } catch (error) {
      console.log(error);
      getPreSaleCount();
    }
  }

  function beforeMint() {
    const value =
      (Math.round(mintingAmount * mintPrice * 10000000) / 10000000) * 10 ** 18;
    //Check Wallet Balance;
    if (walletBalance < value) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "Your balance is insufficient",
      });
      return false;
    }
    //Check Remain NFT
    if (stepSupply < stepCount + mintingAmount) {
      swal.fire({
        icon: "error",
        title: "Error",
        text: "NFTs is insufficient",
      });
      return false;
    }
    //In Presale
    if (currentStep === 1) {
      //Check WhiteList
      if (userLimit === 0) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "You are not in white list",
        });
        return false;
      }
      //Check WhiteList Remain
      if (walletPreSaleCount + mintingAmount > userLimit) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "You don't have enough whitelist balances",
        });
        return false;
      }
    }
    return true;
  }

  async function mintNFT() {
    if (isMintPossible) {
      if (web3 && beforeMint()) {
        setIsMinting(true);
        const contract = new web3.eth.Contract(ABI, ContractAddress);
        try {
          await contract.methods
            .mintNFT(mintingAmount, userLimit, userProof)
            .send({
              from: walletAddress,
              value: (Math.round(mintingAmount * mintPrice * 10000000) / 10000000) *
              10 ** 18,
            })
            .on("error", function (error) {
              console.log(error.message);
              setIsMinting(false);
            })
            .then(() => {
              swal.fire({
                icon: "success",
                title: "Mint Success!",
              });
              setIsMinting(false);
              getCurrentStep();
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  return (
    <MainEl>
      <TitleText>My Square NFT</TitleText>
      <MintingStage>{currentStepText}</MintingStage>
      <ContentsEl>
        <ImgBox src={"/images/nft.jpg"} alt="nft" />
        <RightContentsEl>
          <CountDownEl>
            {currentStep === 4 ? (
              <div></div>
            ) : (
              <CountDownBox>
                <img src="/icons/Time.svg" height="16" alt="time" />
                {/* <CountDownText>5 days 13 hours 39 mins 9 sec</CountDownText> */}
                <CountDownText>{countDownText}</CountDownText>
              </CountDownBox>
            )}
            <WalletAddressBox onClick={openModalHandler}>
              <img src={"/icons/Ethereum.png"} alt="Ethereum" />
              {walletAddress.substr(0, 5) +
                "..." +
                walletAddress.substr(37, 42)}
            </WalletAddressBox>
          </CountDownEl>
          <MintCountEl>
            <CountTextBox>
              <RightBoxText>
                NFT {language === "KR" ? "잔여수량" : "Remaining"}
              </RightBoxText>
              <RightBoxHighlightText>
                {stepSupply - stepCount}
              </RightBoxHighlightText>
            </CountTextBox>
            <GaugeBox>
              <GaugeBackground />
              <Gauge percent={stepCount / stepSupply} />
            </GaugeBox>
            <GaugeIndexTextBox>
              <span>0</span>
              <span>{stepSupply}</span>
            </GaugeIndexTextBox>
          </MintCountEl>
          <PriceEl>
            <RightBoxText>Price</RightBoxText>
            <PriceBox>
              <PriceText>
                {Math.round(mintPrice * mintingAmount * 10000000) / 10000000}
              </PriceText>
              <RightBoxText>ETH</RightBoxText>
            </PriceBox>
          </PriceEl>
          <AmountEl>
            <RightBoxText>Amount</RightBoxText>
            <AmountControlBox>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                cursor={"pointer"}
                onClick={(e) => {
                  amountControlHandler("minus", e);
                }}
              >
                <path
                  d="M12 1.5C9.22565 1.53357 6.5744 2.65059 4.61249 4.61249C2.65059 6.5744 1.53357 9.22565 1.5 12C1.53357 14.7743 2.65059 17.4256 4.61249 19.3875C6.5744 21.3494 9.22565 22.4664 12 22.5C14.7743 22.4664 17.4256 21.3494 19.3875 19.3875C21.3494 17.4256 22.4664 14.7743 22.5 12C22.4664 9.22565 21.3494 6.5744 19.3875 4.61249C17.4256 2.65059 14.7743 1.53357 12 1.5ZM18 12.75H12.75H11.25H6V11.25H11.25H12.75H18V12.75Z"
                  fill={mintingAmount > 1 ? Colors.purple02 : Colors.gr07}
                />
              </svg>
              <MintAmountText>{mintingAmount}</MintAmountText>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                cursor={"pointer"}
                onClick={(e) => {
                  amountControlHandler("plus", e);
                }}
              >
                <path
                  d="M12 1.5C9.22565 1.53357 6.5744 2.65059 4.61249 4.61249C2.65059 6.5744 1.53357 9.22565 1.5 12C1.53357 14.7743 2.65059 17.4256 4.61249 19.3875C6.5744 21.3494 9.22565 22.4664 12 22.5C14.7743 22.4664 17.4256 21.3494 19.3875 19.3875C21.3494 17.4256 22.4664 14.7743 22.5 12C22.4664 9.22565 21.3494 6.5744 19.3875 4.61249C17.4256 2.65059 14.7743 1.53357 12 1.5ZM18 12.75H12.75V18H11.25V12.75H6V11.25H11.25V6H12.75V11.25H18V12.75Z"
                  fill={
                    mintingAmount < maxMintPerTx ? Colors.purple02 : Colors.gr07
                  }
                />
              </svg>
            </AmountControlBox>
          </AmountEl>
          {!isMinting ? (
            <MintingButton isActive={isMintPossible} onClick={mintNFT}>
              Minting
            </MintingButton>
          ) : (
            <LoadingButton>
              <Lottie
                style={{ width: "60px", height: "60px" }}
                animationData={animationData}
              />
            </LoadingButton>
          )}
        </RightContentsEl>
      </ContentsEl>
      {isModalOpen && (
        <ModalDisconnect
          setIsModalOpen={setIsModalOpen}
          disconnectHandler={disconnectHandler}
          language={language}
        />
      )}
    </MainEl>
  );
}
export default MintingPage;
