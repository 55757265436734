import Header from "./components/Header";
import MintingPage from "./pages/MintingPage";
import ConnectWalletPage from "./pages/ConnectWalletPage";
import Footer from "./components/Footer";

import { useState } from "react";

function App() {
  const [web3, setWeb3] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [language, setLanguage] = useState("KR");
  return (
    <div>
      <Header language={language} setLanguage={setLanguage} />
      {isConnected ? (
        <MintingPage
          web3={web3}
          setWeb3={setWeb3}
          setIsConnected={setIsConnected}
          walletAddress={walletAddress}
          language={language}
        />
      ) : (
        <ConnectWalletPage
          web3={web3}
          setWeb3={setWeb3}
          setIsConnected={setIsConnected}
          setWalletAddress={setWalletAddress}
          language={language}
        />
      )}
      <Footer />
    </div>
  );
}

export default App;
